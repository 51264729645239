import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Cart from '../components/Cart'
import Skus from '../components/Products/Skus'

const AdvancedExamplePage = () => (
  <Layout>
    <SEO title="WHC - GEAR - CART" />
    <h1>This is the WHC - GEAR available right now</h1>
    <Cart>
      <Skus />
    </Cart>
    <Link to="/about">About this store.</Link>
  </Layout>
)

export default AdvancedExamplePage
